import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import LineHead from '../../../components/LineHead';

const News = ({ content }) => {
    const { forumNewsHead, forumNewsFeed } = content;

    return (
        <div className={styles.news}>
            <Chip id="260338" type="struct" title="论坛新闻头" groupName="论坛新闻" content={forumNewsHead}>
                <LineHead />
            </Chip>
            <Chip id="286052" type="recommend" title="论坛新闻信息流" groupName="论坛新闻">
                <div className={styles.newsBox}>
                    {forumNewsFeed.slice(0, 6).map((item, index) => {
                        return (
                            <div key={index} className={styles.newsBoxItem}>
                                <a href={item.url} target="_blank">
                                    {item.title}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </Chip>
        </div>
    );
};

News.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(News);
