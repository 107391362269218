import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import ImageHead from '../../../components/ImageHead';

const GoodVideo = ({ content = {} }) => {
    const { goodVideoHead, goodVideoFeed } = content;

    return (
        <div className={styles.goodVideo}>
            <Chip id="260341" type="struct" title="精彩视频头" groupName="精彩视频" content={goodVideoHead}>
                <ImageHead />
            </Chip>
            <Chip id="286053" type="recommend" title="精彩视频信息流" groupName="精彩视频">
                <div className={styles.goodVideoBox}>
                    {goodVideoFeed.map((item, index) => {
                        return (
                            <a key={index} className={styles.goodVideoItem} href={item.url} target="_blank">
                                <div className={styles.goodVideoImage}>
                                    <img
                                        className={styles.goodVideoPic}
                                        src={formatImageWebpAuto(item?.thumbnails?.image?.[0]?.url, 235, 133)}
                                    />
                                    <span className={styles.goodVideoIcon} />
                                </div>
                                <div className={styles.goodVideoTitle}>{item.title}</div>
                            </a>
                        );
                    })}
                </div>
            </Chip>
        </div>
    );
};

GoodVideo.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(GoodVideo);
