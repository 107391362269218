import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import ImageHead from '../../../components/ImageHead';
// import ScheduleTable from '../../../components/ScheduleTable';

// head: cooperationHead,
//         headChip: {
//             id: '260347',
//             type: 'struct',
//             title: '合作伙伴头',
//             groupName: '导航',
//             content: cooperationHead,
//         },
//         list: cooperationList,
//         listChip: {
//             id: '260348',
//             type: 'struct',
//             title: '合作伙伴列表',
//             groupName: '合作伙伴',
//             content: cooperationList,
//         },

const Schedule = ({ content }) => {
    const { head, headChip, list, listChip } = content;

    return (
        <div className={styles.organ}>
            <Chip {...headChip}>
                <ImageHead />
            </Chip>
            <Chip {...listChip}>
                <div className={styles.organBox}>
                    {list.map((item, index) => {
                        return <img key={index} className={styles.organPic} src={item.imageUrl} />;
                    })}
                </div>
            </Chip>
        </div>
    );
};

Schedule.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Schedule);
