import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
import Footer from '../../../components/Footer';
// 碎片组件
import Chip from 'Chip';
const BottomFooter = ({ content }) => {
    const { footer } = content;

    return (
        <div className={styles.footer}>
            <Chip id="20012" type="struct" title="底部公用版权" groupName="底部" content={footer}>
                <Footer />
            </Chip>
        </div>
    );
};

BottomFooter.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(BottomFooter);
