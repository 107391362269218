import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import chipDataTransform from 'chipDataTransform';
import Nav from '../../components/Nav';
import Footer from './Footer';
import styles from './index.css';
import SliderComponent from './SliderComponent';
import News from './News';
import Schedule from './Schedule';
import GoodVideo from './GoodVideo';
import HeadLines from './HeadLines';
import Organ from './Organ';
// import Organizer from '../../components/Organizer';
// import Cooperation from '../../components/Cooperation';

const Layout = ({ content }) => {
    const {
        banner,
        navigation,
        footer,
        slider,
        forumNewsHead,
        forumNewsFeed,
        scheduleHead,
        scheduleList,
        goodVideoHead,
        goodVideoFeed,
        headLinesHead,
        headLinesFeed,
        viewpointHead,
        viewpointFeed,
        holdHead,
        holdLLList,
        undertakeHead,
        undertakeList,
        friendHead,
        friendList,
        cooperationHead,
        cooperationList,
    } = content;

    // 底部信息配置
    const footerData = {
        footer,
    };

    const componentOptions = {
        extendData: '',
        length: 5,
    };
    const navData = {
        navigation,
        activeIndex: 0,
    };
    const newsData = {
        forumNewsHead,
        forumNewsFeed,
    };
    const scheduleData = {
        scheduleHead,
        scheduleList,
    };
    const goodVideoData = {
        goodVideoHead,
        goodVideoFeed,
    };
    const headLinesData = {
        headLinesHead,
        headLinesFeed,
    };
    const viewpointData = {
        viewpointHead,
        viewpointFeed,
    };
    const holdData = {
        head: holdHead,
        headChip: {
            id: '260344',
            type: 'struct',
            title: '主办单位头',
            groupName: '主办单位',
            content: holdHead,
        },
        list: holdLLList,
        listChip: {
            id: '260349',
            type: 'struct',
            title: '主办单位列表',
            groupName: '主办单位',
            content: holdHead,
        },
    };
    const undertakeData = {
        head: undertakeHead,
        headChip: {
            id: '260345',
            type: 'struct',
            title: '承办单位头',
            groupName: '承办单位',
            content: undertakeHead,
        },
        list: undertakeList,
        listChip: {
            id: '260350',
            type: 'struct',
            title: '承办单位列表',
            groupName: '承办单位',
            content: undertakeList,
        },
    };
    const friendData = {
        head: friendHead,
        headChip: {
            id: '260346',
            type: 'struct',
            title: '友情链接头',
            groupName: '友情链接',
            content: friendHead,
        },
        list: friendList,
        listChip: {
            id: '260351',
            type: 'struct',
            title: '友情链接列表',
            groupName: '友情链接',
            content: friendList,
        },
    };
    const cooperationData = {
        head: cooperationHead,
        headChip: {
            id: '260347',
            type: 'struct',
            title: '合作伙伴头',
            groupName: '导航',
            content: cooperationHead,
        },
        list: cooperationList,
        listChip: {
            id: '260348',
            type: 'struct',
            title: '合作伙伴列表',
            groupName: '合作伙伴',
            content: cooperationList,
        },
    };

    useEffect(() => {
        document.body.style.backgroundImage = `url('${banner.imageUrl}')`;
    }, []);

    return (
        <>
            <Chip id="260353" type="struct" title="banner" groupName="banner" content={navData}>
                <div className={styles.banner} />
            </Chip>
            <Chip id="260331" type="struct" title="导航" groupName="导航" content={navData}>
                <Nav />
            </Chip>
            <div className={styles.layout}>
                <div className={styles.column}>
                    <Chip
                        key="slider"
                        id="286041"
                        name="slider"
                        type="recommend"
                        title="轮播图"
                        groupName="轮播图"
                        content={slider}>
                        <SliderComponent componentOptions={componentOptions} />
                    </Chip>
                    <News content={newsData} />
                </div>
                <div className={styles.column}>
                    <Schedule content={scheduleData} />
                    <GoodVideo content={goodVideoData} />
                    <HeadLines content={headLinesData} />
                    <Organ content={cooperationData} />
                </div>
                <Footer content={footerData} />
                <ChipEdit transform={chipDataTransform} />
            </div>
        </>
    );
};

Layout.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Layout);
