import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';

const Nav = ({ content }) => {
    const { navigation, activeIndex } = content;

    return (
        <div className={styles.nav}>
            <div className={styles.navBox}>
                {navigation.map((item, index) => {
                    return (
                        <div className={styles.navItemBox} key={index}>
                            <a
                                href={item.url}
                                target="_blank"
                                className={`${styles.navItem} ${activeIndex === index ? styles.active : ''}`}>
                                <div className={styles.navItemText}>{item.title}</div>
                            </a>
                            {index + 1 !== navigation.length && <div className={styles.navDot}>·</div>}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Nav.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Nav);
