import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import { NewsStream } from '@ifeng-fe/news-stream';
import '@ifeng-fe/news-stream/lib/NewsStream/style/news-stream-global.css';
import ImageHead from '../../../components/ImageHead';
import { formatNewsstream } from '../../../../services/api';

const HeadLines = ({ content }) => {
    const { headLinesHead, headLinesFeed } = content;

    const newsStreamProps = {
        content: formatNewsstream(headLinesFeed, '286048'), // 截取信息流条20条
        columnId: '286048', // 加载更多时的碎片ID
        isEnd: !(headLinesFeed && headLinesFeed.length > 20), // 是否已显示全部，true：已显示全部，false：还有数据
        isDisplaySource: true, // 是否显示新闻来源
        repeatedID: [], // 信息流内用于排重的id
        ad: [{}],
        dataTypeName: 'recommendFragment',
    };

    return (
        <div className={styles.headLines}>
            <Chip id="260342" type="struct" title="湾区头条头" groupName="湾区头条" content={headLinesHead}>
                <ImageHead />
            </Chip>
            <Chip id="286048" type="recommend" title="湾区头条信息流" groupName="湾区头条">
                <div className={styles.newsItem}>
                    <NewsStream {...newsStreamProps} />
                </div>
            </Chip>
        </div>
    );
};

HeadLines.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(HeadLines);
