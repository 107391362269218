import { jsonp, ajax } from '@ifeng-fe/ui_base';
import { formatImage, formatUrl } from '@ifeng-fe/public_method';
import { getSkey, handleNewstime } from '../utils/utils';

// apiUrl为webpack注入的全局变量
/* eslint-disable no-undef */
let apiBaseUrl = apiUrl;

/* eslint-enable no-undef */
if (typeof window === 'object' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

const md5 = require('md5');

const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    /* eslint-disable */
    while (typeof window === 'object' && window[jsonpCallbackName]) {
        /* eslint-enable */
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

// 获取自选股数据
export const getMyStockData = async num => {
    return await jsonp('//apiapp.finance.ifeng.com/mystock/get', {
        data: { num },
        jsonpCallback: createJsonpCallbackName('getMyStockData'),
        cache: false,
    });
};

// 获取股票数据
export const getStockData = async codeList => {
    const callback = createJsonpCallbackName('getFinanceData');

    return await jsonp('//hq.finance.ifeng.com/q.php', {
        data: {
            l: codeList.join(','),
            f: 'json',
            e: `${callback}(json_q)`,
        },
        jsonpCallback: callback,
        cache: false,
    });
};

// 查询股票，资金，证券等数据
export const getFinanceData = async (type, str) => {
    const callback = createJsonpCallbackName('getFinanceData');

    return await jsonp('//app.finance.ifeng.com/hq/suggest_v2.php', {
        data: {
            t: type,
            q: str,
            cb: `${callback}(suggest_json)`,
        },
        jsonpCallback: callback,
        cache: false,
    });
};

// 获取恒生指数数据
export const getHkStockData = async () => {
    const data = await jsonp(
        '//api.iclient.ifeng.com/hkStockMarke?gv=6.3.9&av=0&proid=ifengnews&os=ios_12.1.4&vt=5&screen=750x1334&publishid=4002&uid=e53ec6cf349b4738b2c73f948ecbcc0f&nw=wifi&loginid=76437432&token=E2BFFC6583DC76F10D4E3147DCB1331A&st=1551752657&sn=c7107166d2de111c6f472e6f22c2900a&dlt=40.011187&dln=116.497379&dcy=%E5%8C%97%E4%BA%AC%E5%B8%82&dpr=%E5%8C%97%E4%BA%AC%E5%B8%82',
        {
            cache: false,
            jsonpCallback: 'getHkStockData',
        },
    );

    if (data.hk0000100_dly) return data.hk0000100_dly;

    return [];
};

// 获取资金流向数据
export const getFundsFlowData = async () => {
    return await jsonp('//i.finance.ifeng.com/moneyflow/flow/So', {
        jsonpCallback: 'getFundsFlowData',
        cache: false,
    });
};

// 获取文章评论数
export const getCommentCount = async url => {
    return await jsonp('//comment.ifeng.com/get.php', {
        data: {
            job: 4,
            format: 'js',
            callback: 'getAllComment1',
            docurl: url.join('|'),
        },
        jsonCallback: 'getAllComment1',
        cache: false,
    });
};

// 获取信息流首页数据
export const getNewInfoIndexList = async key => {
    const data = await jsonp(`${apiBaseUrl}/finance/index/newInfoIndex/${key}/getNewInfoIndexList`, {
        jsonpCallback: 'getNewInfoIndexList',
        cache: false,
    });

    let result = [];

    if (data.code === 0 && data.data && data.data.length > 0) {
        result = data.data.map(item => ({
            base62Id: item.base62Id,
            thumbnails: item.thumbnail ? formatImage(item.thumbnail, 144, 96) : '',
            commentUrl: item.commentUrl,
            pcUrl: item.url,
            url: formatUrl(item.url),
            title: item.title,
            source: item.source,
            newsTime: handleNewstime(item.newsTime),
            skey: getSkey(item.title, item.url),
        }));
    }

    return result;
};

// 获取信息流其他数据
export const getInfoDataList = async key => {
    const data = await jsonp(`${apiBaseUrl}/finance/index/infoData/${key}/getInfoDataList`, {
        jsonpCallback: 'getInfoDataList',
        cache: false,
    });

    let result = [];

    if (data.code === 0 && data.data && data.data.length > 0) {
        result = data.data.map(item => ({
            base62Id: item.base62Id,
            thumbnails:
                item.thumbnails &&
                item.thumbnails.image &&
                item.thumbnails.image.value &&
                item.thumbnails.image.value[0]
                    ? formatImage(item.thumbnails.image.value[0].url, 144, 96)
                    : '',
            commentUrl: item.commentUrl,
            pcUrl: item.url,
            url: formatUrl(item.url),
            title: item.title,
            source: item.source,
            newsTime: handleNewstime(item.newsTime),
            skey: item.skey,
        }));
    }

    return result;
};

// 获取财联社数据
export const getClsData = async today => {
    const callback = createJsonpCallbackName('getLiveData');

    return await jsonp('//api3.finance.ifeng.com/live/getclsnew', {
        data: {
            beg: today / 1000,
            end: (today - 24 * 60 * 60 * 1000 * 2) / 1000,
        },
        jsonp: 'cb',
        jsonpCallback: callback,
        cache: false,
    });
};

// 刷新财联社数据
export const refreshClsData = async lastid => {
    return await jsonp('//api3.finance.ifeng.com/live/getclsnew', {
        data: {
            lastid,
        },
        jsonp: 'cb',
        jsonpCallback: 'addNewData',
        cache: false,
    });
};

// 获取资金流向排行数据
export const getFundsFlowRank = async type => {
    const data = await jsonp('//app.finance.ifeng.com/stockindex/getZijinRank.php', {
        data: {
            type,
        },
        jsonpCallback: 'getFundsFlowRank',
        cache: false,
    });

    if (data.data) {
        data.data.forEach(item => {
            if (item.news !== '') {
                item.news = formatUrl(item.news);
            }
        });
    }

    return data;
};

// 获取热门股票数据
export const getHotStockData = async () => {
    return await jsonp('//apiapp.finance.ifeng.com/hotstockrank', {
        data: {
            type: 'wx',
            callback: 'getHotStockData',
        },
        jsonpCallback: 'getHotStockData',
        cache: false,
    });
};

// 获取股票涨跌排行数据
export const getStockRank = async type => {
    const data = await jsonp('//app.finance.ifeng.com/stockindex/getStockRank.php', {
        data: {
            type,
        },
        jsonpCallback: 'getStockRank',
        cache: false,
    });

    if (data.data) {
        data.data.forEach(item => {
            if (item.news !== '') {
                item.news = formatUrl(item.news);
            }
        });
    }

    return data;
};

/**
 * 港股首页搜索股票推荐接口
 * @param {string} keyword
 */
export const searchStockSuggest = async keyword => {
    const timestamp = Date.parse(new Date());
    const token = md5(`${timestamp}#ifeng`);

    return await jsonp(
        `http://ifeng.szfuit.com:83/hkquote/api/search.php?keyword=${keyword}&number=20&_callback=search_suggest&_timestamp=${timestamp}&_token=${token}`,
        {
            cache: false,
            jsonpCallback: 'search_suggest',
        },
    );
};

/**
 * institute财经研究院专家团队页面顾问接口
 */
export const instituteConsultantData = async () => {
    return await jsonp('//yjy.finance.ifeng.com/index/getWyList?type=4', {
        cache: false,
        jsonpCallback: 'success_jsonpCallback4',
    });
};
/**
 * institute财经研究院专家团队页面学术委员会接口
 */
export const instituteScienceData = async () => {
    return await jsonp('//yjy.finance.ifeng.com/index/getWyList?type=1', {
        cache: false,
        jsonpCallback: 'success_jsonpCallback1',
    });
};

/**
 * institute财经研究院专家团队页面高级研究员接口 senior research fellow;

 */
export const instituteSeniorFeData = async () => {
    return await jsonp('//yjy.finance.ifeng.com/index/getWyList?type=2', {
        cache: false,
        jsonpCallback: 'success_jsonpCallback2',
    });
};

/**
 * institute财经研究院专家团队页面特邀研究员接口
 */
export const instituteInvitedFeData = async () => {
    return await jsonp('//yjy.finance.ifeng.com/index/getWyList?type=5 ', {
        cache: false,
        jsonpCallback: 'success_jsonpCallback5',
    });
};

/**
 * institute财经研究院专家团队页面特约经济学家接口
 */
export const instituteEconomistData = async () => {
    return await jsonp('//yjy.finance.ifeng.com/index/getWyList?type=3', {
        cache: false,
        jsonpCallback: 'success_jsonpCallback3',
    });
};

// 获取视频播放数
export const getVideoComment = async vid => {
    // const { schemaCheck } = await import('./validate');

    let apiUrl = '//survey.news.ifeng.com/api/getaccumulatorweight?';

    // 接口拼接
    if (vid.length > 0) {
        vid.forEach(item => {
            apiUrl += `key[]=${item}&`;
        });
    }

    let data = await jsonp(apiUrl, {
        data: {
            format: 'js',
            serviceid: 1,
        },
        jsonCallback: 'getVideoComment1',
        cache: false,
    });
    // const requestUrl = `//survey.news.ifeng.com/api/getaccumulatorweight?format=js&key=${vid}&callback=getaccumulator`;

    // data = schemaCheck(data.data, 'voteNumSchema', requestUrl);
    data = data.data;

    return data ? data : null;
};

/**
 * 获取財經直播室数据
 */

// 加载更多财联社数据
export const moreClsData = async lastid => {
    return await jsonp(`//api3.finance.ifeng.com/live/getclsold?lastid=${lastid}&num=20`, {
        data: {
            lastid,
        },
        jsonp: 'cb',
        jsonpCallback: 'addNewData',
        cache: false,
    });
};

// 理财首页信息流
export const getMoneyStream = async (key, start, length) => {
    // const length = len || 12;
    const addKey = key.replace(/-/g, '').replace(/_/g, '');
    const data = await jsonp(`${apiBaseUrl}/index/moneyData/${key}/${start}/${length}/getMoneyDataCb${addKey}`, {
        jsonpCallback: `getMoneyDataCb${addKey}`,
        cache: false,
    });

    // console.log('apidata==', data);

    return data;
};

/**
 * 获取客户端精品池中财经栏目下文章
 * @param {String} selectedId 精品池id
 * @param {Number} current current
 * @param {Number} pageSize pageSize
 */
export const getFinanceSelectedData = async (selectedId, current, pageSize = 20) => {
    const isVideoShare = (typeof window === 'object' && window.allData && window.allData.isVideoShare) || false;
    const proid = isVideoShare ? 'ifengvideoh5' : 'ifengnewsh5';

    return await jsonp(
        `${apiBaseUrl}/finance/studio/getSelectedPoolData/${selectedId}/${current}/${pageSize}/${proid}/getFinanceDataCb`,
        {
            jsonpCallback: 'getFinanceDataCb',
            cache: false,
        },
    );
};

// 行情中心首页列表
export const getSituationData = async (key, type) => {
    const data = await jsonp(`//data.finance.ifeng.com/${key}`, {
        data: { type },
        jsonpCallback: 'getSituationData',
        cache: false,
    });

    return data;
};

export const getMarketIsOpen = async () => {
    const data = await jsonp('//data.finance.ifeng.com/stockinfo/marketinfo', {
        jsonpCallback: 'marketinfo',
        cache: false,
    });

    return data;
};

/**
 * 财经行情中心 新股落地页 新股申购
 * @param {*} pno 页码
 * @param {*} sort 排序依据
 * @param {*} psize 单页数量
 * @param {*} order 排序方式
 * @param {*} type 类型
 */
export const getPurchaseTableData = async (pno, sort, psize, order, type = 'all') => {
    const res = await jsonp('//data.finance.ifeng.com/ipo/shengou', {
        data: { pno, sort, psize, order, type },
        jsonpCallback: 'test',
        cache: false,
    });

    if (res.code === 1) {
        return res.data;
    } else {
        return null;
    }
};

/**
 * 财经行情中心 新股落地页 新股上会
 * @param {*} pno 页码
 * @param {*} psize 单页数量
 * @param {*} disp 状态
 * @param {*} type 类型
 */
export const getShanghuiTableData = async (pno, sort, psize, order, type = 'all', disp = 0) => {
    const res = await jsonp('//data.finance.ifeng.com/ipo/shanghui', {
        data: { pno, sort, psize, order, type, disp },
        jsonpCallback: 'test',
        cache: false,
    });

    if (res.code === 1) {
        return res.data;
    } else {
        return null;
    }
};

/**
 * 财经行情中心 新股落地页 新股申报
 * @param {*} pno 页码
 * @param {*} psize 单页数量
 * @param {*} disp 状态
 * @param {*} type 类型
 */
export const getDeclareTableData = async (pno, psize, disp = 0, type = 'all') => {
    const res = await jsonp('//data.finance.ifeng.com/ipo/shenbao', {
        data: { pno, psize, disp, type },
        jsonpCallback: 'test',
        cache: false,
    });

    if (res.code === 1) {
        return res.data;
    } else {
        return null;
    }
};

/**
 * 财经行情中心 新股落地页 新股日历
 * @param {*} date 日期
 */
export const getCalenderTableData = async date => {
    const res = await jsonp('//data.finance.ifeng.com/ipo/calendar', {
        data: { date },
        jsonpCallback: 'test',
        cache: false,
    });

    if (res.code === 1) {
        return res.data;
    } else {
        return null;
    }
};

export const getStockgStzgcVideo = async keys => {
    const res = await jsonp(`${apiBaseUrl}/getVideoData/${keys}/getStockgStzgcVideoCb`, {
        jsonpCallback: 'getStockgStzgcVideoCb',
        cache: false,
    });

    return res.data;
};

// 获取分析师数据
export const getAnalyzerInfo = async (name, type) => {
    const callback = createJsonpCallbackName('updateAnalyzerInfo');

    const data = await jsonp('//app.finance.ifeng.com/gszb/user_ol.php', {
        data: {
            name,
            type,
            cb: callback,
        },
        jsonpCallback: callback,
        cache: false,
    });

    return data.map(item => ({
        url: item.url === '' ? '' : formatUrl(item.url),
        image: formatImage(item.image, 60, 60),
    }));
};

// 获取分析师答疑数据
export const getQAData = async (name, type) => {
    const callback = createJsonpCallbackName('getQAData');

    return await jsonp('//app.finance.ifeng.com/gszb/a_data.php', {
        data: {
            name,
            type,
            cb: callback,
        },
        jsonpCallback: callback,
        cache: false,
    });
};

// 投资观察，获取分析师答疑，参数与股票首页不同
export const getQADataTzgc = async (user, type) => {
    const callback = createJsonpCallbackName('getQAData');

    return await jsonp('//app.finance.ifeng.com/gszb/a_data.php', {
        data: {
            user,
            type,
            cb: callback,
        },
        jsonpCallback: callback,
        cache: false,
    });
};

// 刷新24小时直播数据
export const refreshLiveData = async lastid => {
    return await jsonp('//api3.finance.ifeng.com/live/getnew', {
        data: {
            lastid,
            level: 1,
            dist: 1,
        },
        jsonp: 'cb',
        jsonpCallback: 'addNewData',
        cache: false,
    });
};

// 财经直播间 全部分栏  文章打开
export const getFinanceShareIdData = async () => {
    return await jsonp(`${apiBaseUrl}/finance/studio/getFinanceShareNewsId/getFinanceShareNewsIdCb`, {
        jsonpCallback: 'getFinanceShareNewsIdCb',
        cache: false,
    });
};
// 财经直播间 文章刷新
export const getFinanceNewShareIdData = async lastid => {
    return await jsonp(`${apiBaseUrl}/finance/studio/getFinanceNewShareNewsId/${lastid}/getFinanceNewShareNewsIdCb`, {
        jsonpCallback: 'getFinanceNewShareNewsIdCb',
        cache: false,
    });
};
// 下拉加载更多数据
export const getFinanceMoreShareIdData = async lastid => {
    return await jsonp(`${apiBaseUrl}/finance/studio/getFinanceMoreShareNewsId/${lastid}/getFinanceMoreShareNewsIdCb`, {
        jsonpCallback: 'getFinanceMoreShareNewsIdCb',
        cache: false,
    });
};

// 获取结构化碎片财经插入直播数据
export const getFinanceStructData = async () => {
    let result = await jsonp('//shankapi.ifeng.com/feedflow/get/struct/260068/getFinanceStrutData', {
        jsonpCallback: 'getFinanceStrutData',
        cache: false,
    });

    if (result && result.code === 0 && result.data && result.data.length > 0) {
        result = result.data;
    } else {
        result = [];
    }

    return result;
};

// 判断数据是数组还是对象并返回数据
const isArray = data => Array.isArray(data);
const isObject = data => typeof data === 'object' && Object.prototype.toString.call(data) === '[object Object]';

// 格式化数据流的同时需要去重
const formatNewsstream = function formatNewsstream(newsstream = [], idArr = [], isMobile = false, breaks = true) {
    const data = [];
    let i = 0;

    for (const item of newsstream) {
        const {
            id,
            title,
            url,
            commentUrl,
            skey,
            newsTime,
            source,
            type,
            thumbnail,
            thumbnails,
            videoCount,
            guid,
            base62Id,
            wwwUrl
        } = item;

        if (idArr.includes(id)) continue;

        // 移动端自媒体兼容
        let url2 = url;

        if (isMobile === true && !item.searchPath) {
            let id2 = id;

            if (/^[0-9]+$/.test(id2) && base62Id) id2 = base62Id; // 如果ID为19个数字组成，就用短ID，否则就直接使用原始ID

            url2 = `https://ifenghuanghao.ifeng.com/${id2}/news.shtml`;
        }

        // 格式化thumbnails字段
        let formatThumbnails = thumbnails;

        if (isArray(thumbnails)) {
            if (thumbnails.length > 0) {
                formatThumbnails = {
                    image: thumbnails.map(url => ({ url })),
                };
            } else {
                formatThumbnails = undefined;
            }
        } else if (typeof thumbnails === 'string' && thumbnails !== '') {
            try {
                formatThumbnails = JSON.parse(thumbnails);
            } catch (err) {
                /* 什么也不干 */
            }
        }

        data.push({
            id,
            title,
            url: url2,
            commentUrl, // 获取评论用
            skey,
            newsTime,
            source,
            type,
            thumbnail,
            thumbnails: formatThumbnails, // 图片地址
            videoCount, // 是否有视频,
            guid,
            base62Id,
            wwwUrl,
        });

        i += 1;

        if (i === 20 && breaks) {
            break;
        }
    }

    return data;
};

exports.formatNewsstream = formatNewsstream;

// 获取大亚湾关于论坛数据
export const getRecommendData = async (id, pageNum, len) => {
    let result = await jsonp(`https://shankapi.ifeng.com/api/recommend/${id}/${pageNum}/${len}/getRecommendData`, {
        jsonpCallback: 'getRecommendData',
        cache: false,
    });

    if (result?.data && result.data.length > 0) {
        result = result.data;
    } else {
        result = [];
    }

    return result;
};
