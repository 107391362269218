import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';

const ImageHead = ({ content = {} }) => {
    return (
        <div className={styles.imageHead}>
            <span className={styles.imageHeadTitle}>{content.name || ''}</span>
            {content.url && (
                <a href={content.url} target="_blank">
                    <span className={styles.imageHeadMore}>更多</span>
                </a>
            )}
        </div>
    );
};

ImageHead.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(ImageHead);
