import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';

const LineHead = ({ content = {} }) => {
    return (
        <div className={styles.lineHead}>
            <span className={styles.lineHeadTitle}>{content.name || ''}</span>
            {content.url && (
                <a href={content.url} target="_blank">
                    <span className={styles.lineHeadMore}>更多</span>
                </a>
            )}
        </div>
    );
};

LineHead.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(LineHead);
